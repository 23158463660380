import { each } from 'jquery';
import SlideInCard  from './classes/SlideInCard.js';
// require('./bootstrap');
global.lozad = require('lozad')
window.$ = window.jQuery = require('jquery');
require('jquery-mask-plugin');
const { forEach } = require('lodash');
const swiper = require('swiper');
window._swiper = swiper.default;
window._swiperPagination = swiper.Pagination;
window._swiperMousewheel = swiper.Mousewheel;
window._swiperNavigation = swiper.Navigation;
window._swiperController = swiper.Controller;
window._swiperAutoplay = swiper.Autoplay;
window._swiperKeyboard = swiper.Keyboard;
window._swiperCreativeEffect= swiper.EffectCreative;
window._swiperEffectFade= swiper.EffectFade;
window._swiperFreeMode = swiper.FreeMode;
global.mixitup = require('mixitup');
window._formOperations = require('./5-form-validation.js');

//frontend features

const cylinder = document.querySelector('.cylinder');
const duration = 800; // Animation duration in milliseconds
let currentRotation;
let targetRotation;
let start;
window.request = null;

window.revolve = (curPosition, targetPosition) => {
    const degree = -42; // Set the desired degree value here

    window.request = requestAnimationFrame(revolve);
    if(!start){
        currentRotation = parseInt(curPosition) * degree;
        targetRotation = parseInt(targetPosition) * degree;
        start = performance.now();
    }

    const elapsed = performance.now() - start;
    const t = elapsed / duration;
    const p = springWobbly(t);


    const rotation = lerp(currentRotation, targetRotation, p);
    cylinder.style.transform = `rotate(${rotation}deg)`;
    if(elapsed > duration) {
        cancelAnimationFrame(window.request);
        start = null;
        window.request = null;
    }
}

function toggleCollapsible(collapsible){

}

$('.tabs .button-container button').on('click', function (e) {
    // get data ID
    let allTabs = $(this).parents('.tabs');
    var id = $(this).data('target');
    var target = allTabs.find(`.tab-container .tab[data-id=${id}]`);
    allTabs.find('.active').removeClass('active');
    $(this).addClass('active')
    target.addClass('active')
    allTabs.find('.icon-bg.active').removeClass('active');
    allTabs.find('.radio.active').removeClass('active');
    $(this).parents('.icon-bg').addClass('active');
    allTabs.find(`.radio[data-target=${id}]`).addClass('active');
});

$('.accordion button').on('click', function(e){
    let targetId = $(this).data('target');
    let parent = $(this).closest('.accordion');
    let allButtons =  parent.find('button');

    // $.each(parent.children('.card'), function(){
    //     $(this).find('button').addClass('collapsed');
    //     $(this).find('button').attr('aria-expanded','false');
    //     $(this).children('.collapsing').attr('style','');
    // });

    if($(this).attr('aria-expanded') == "false"){
        allButtons.addClass('collapsed');
        allButtons.attr('aria-expanded','false');
        parent.find('.collapsing').attr('style','');
        $(this).removeClass('collapsed');
        $(this).attr('aria-expanded','true');
        $(targetId).css('height',$(targetId).children('.card-body').outerHeight())

    }else{
        $(this).attr('aria-expanded','false');
        $(targetId).attr('style', '');
        $(this).addClass('collapsed');
    }
});
$('.stack-tabs .tab h2').on('click', function(e){
    let parent = $(this).parents('.tab');
    let target = parent.find('.tab-content');
    let allTabs = parent.parents('.stack-tabs').find('.tab');
    let targetId = parent.data('target');
    let targetImg = parent.parents('.stack-tabs').find(` .img-container img[data-id=${targetId}]`);
    let activeImg = parent.parents('.stack-tabs').find(`.img-container img.active`);
    let targetPagination = parent.parents('.stack-tabs').find(` .pagination div[data-id=${targetId}]`);
    let activePagination = parent.parents('.stack-tabs').find(`.pagination div.active`);

    changeImg(targetImg, activeImg);
    changePagination(targetPagination, activePagination);

    allTabs.removeClass('active');
    parent.addClass('active');
    target.addClass('active');
});
$('.revolver-grid .trigger').on('click', function(e){
    if(request) return;

    let parent = $(this).parents('.revolver-grid');
    let targetId = $(this).data('target');
    let currentId = parent.find('.tab.active').data('id');
    let allTabs = parent.find('.tab');
    let allChambers = parent.find('.chamber');
    let allTriggers = parent.find('.trigger');

    let targetTab = parent.find(`.tab[data-id=${targetId}]`);
    let targetChamber = parent.find(`.chamber[data-id=${targetId}]`);
    let targetTrigger = parent.find(`.trigger[data-target=${targetId}]`);

    if(targetId != currentId){
        allChambers.removeClass('active');
        allTabs.removeClass('active');
        allTriggers.removeClass('active');

        targetChamber.addClass('active');
        targetChamber[0].style.rotate = `${parseInt(targetId) * 42}deg`;
        revolve(currentId, targetId);
        targetTab.addClass('active');
        targetTrigger.addClass('active');
    }
    // target.addClass('active');

});

window.parent;
$('.background-desc-tabs .switch .btn').on('click', function(e){
    parent = $(this).parents('.background-desc-tabs');
    console.log('clicked')
    let currentDataIndex = parent.attr('data-index');
    let targetDataIndex = $(this).attr('data-index');
    parent.attr('data-index', targetDataIndex);

    $(this).removeClass('active');
    $(this).siblings(`[data-index=${currentDataIndex}]`).addClass('active');

    parent.find(`.tab[data-index=${currentDataIndex}]`).removeClass('active');
    parent.find(`.tab[data-index=${targetDataIndex}]`).addClass('active');

    parent.find(`.bg-image[data-index=${currentDataIndex}]`).removeClass('active');
    parent.find(`.bg-image[data-index=${targetDataIndex}]`).addClass('active');

});

let orbit_state = "";
$('.orbit-container .navigation .next').on('click', function(e){
    const orbitContainer = $(this).parents('.orbit-container');
    const orbit = orbitContainer.find('.orbit');
    switch(orbit_state){
        case "":
            orbit.addClass('state-1');
            orbit_state = "state-1";
            break;
        case "state-2":
            orbit_state = "state-1";
            orbit.removeClass('state-2');
            break;
        default:
            return
            break;
    };
    orbitContainer.find('.nav.prev').addClass('active');
    $(this).removeClass('active');

});
$('.orbit-container .navigation .prev').on('click', function(e){
    const orbitContainer = $(this).parents('.orbit-container');
    const orbit = orbitContainer.find('.orbit');
    switch(orbit_state){

        case "state-1":
            orbit.addClass('state-2');
            orbit_state = "state-2";
            break;
        default:
            return;
            break;
    };
    orbitContainer.find('.nav.next').addClass('active');
    $(this).removeClass('active');

});

$('header li').on('click', function(e){
    $('header li').removeClass('active');
    $(this).toggleClass('active');
    $('header .menu-fw').toggleClass('active');
    $(`header .menu-fw .sub-menu[data-id=${$(this).data('id')}]`).toggleClass('active');
});
$('.scroll-up').on('click', function(e){
    e.preventDefault();
    window.scrollTo(0, 0);
});
$('header .mobile-menu .slide-down-title:not(.active)').on('click', function(e){
    e.preventDefault();
    if($(this).hasClass('active')) {
        $(this).removeClass('active');
        return
    }
    $(this).parents('.mobile-menu').find('.slide-down-title.active').toggleClass('active');
    $(this).addClass('active');
});
$('header .mobile-menu-activate').on('click', function(e){
    $(this).toggleClass('active');
});
$('.custom-select label').on('click', function(e){
    $(this).parents('.custom-select').removeAttr('open');
});

$('header .menu-fw').on('mouseleave', function(e){
    $('header li.active').removeClass('active');
    $(this).removeClass('active');
    $('header .menu-fw .sub-menu').removeClass('active');
});

async function changeImg(targetImg, activeImg) {
    activeImg.removeClass('active');
    setTimeout(() => {
        activeImg.removeClass('show');
        targetImg.addClass('show');
    }, 310);
    setTimeout(function(){
        targetImg.addClass('active');
    }, 350);
}

function timeoutAnimation(phase0,phase1, phase2, $phase1Dur, $phase2Dur){
    phase0();
    setTimeout(phase1, $phase1Dur);
    setTimeout(phase2, $phase2Dur);
}

async function changePagination(targetPagination, activePagination) {
    activePagination.removeClass('active');
    targetPagination.addClass('active');

}

function rotateAndWobble() {
    $('.cylinder')[0].style.rotate = '42deg';
    setTimeout(function(){
        $('.chamber').addClass('animate');
    }, 400);
        $('.chamber').removeClass('animate');
}

function springWobbly(t) {
    const multiplier = 12;
    const tNum = parseFloat(t);
    const sin = Math.sin(multiplier * tNum);
    const cos = Math.cos(multiplier * tNum);

    return -0.5 * Math.pow(Math.E, -6 * tNum) * (-2 * Math.pow(Math.E, 6 * tNum) + sin + 2 * cos);
  }

  function lerp(a, b, p) {
    return a + p * (b - a);
  }

global.dashCountPagination = (currentClass, totalClass) => {
    return `<div class="${currentClass}"></div>
            <div class="dash"></div>
            <div class="${totalClass}"></div>`;
}

// const observer = lozad(); // lazy loads elements with default selector as '.lozad'
const observer = lozad('.lozad', {
    // rootMargin: '10px 0px', // syntax similar to that of CSS Margin
    threshold: 0.1, // ratio of element convergence
    enableAutoReload: true // it will reload the new image when validating attributes changes
});
observer.observe();
// ... trigger the load of a image before it appears on the viewport

// function setDetailsHeight(selector, wrapper = document) {
//     console.log("set")
//     const setHeight = (detail, open = false) => {
//         detail.open = open;
//         const rect = detail.getBoundingClientRect();
//         detail.dataset.width = rect.width;
//         detail.style.setProperty(open ? `--expanded` : `--collapsed`, `${rect.height}px`);
//     }
//     const details = wrapper.querySelectorAll(selector);
//     console.log(details);
//     const RO = new ResizeObserver(entries => {
//         return entries.forEach(entry => {
//             const detail = entry.target;
//             const width = parseInt(detail.dataset.width, 10);
//             if (width !== entry.contentRect.width) {
//                 detail.removeAttribute('style');
//                 setHeight(detail);
//                 setHeight(detail, true);
//                 detail.open = false;
//             }
//         })
//     });
//     details.forEach(detail => {
//         RO.observe(detail);
//     });
// }

// /* Run it */
// setDetailsHeight('.details');
// document.querySelectorAll("dynamic-card-grid").onmousemove = e => {
//     for(const card of document.getElementsByClassName("card")) {
//       const rect = card.getBoundingClientRect(),
//             x = e.clientX - rect.left,
//             y = e.clientY - rect.top;

//       card.style.setProperty("--mouse-x", `${x}px`);
//       card.style.setProperty("--mouse-y", `${y}px`);
//     };
//   }

$(document).ready(function() {
    $('.dynamic-card-grid').on('mousemove', function(e){
        for(const card of document.getElementsByClassName("card")) {
            const rect = card.getBoundingClientRect(),
                  x = e.clientX - rect.left,
                  y = e.clientY - rect.top;

            card.style.setProperty("--mouse-x", `${x}px`);
            card.style.setProperty("--mouse-y", `${y}px`);
          };
    });
});



$('.details .summary').on('click', function (e) {
    $(this).closest('.details').toggleClass('open');
});

$(document).scroll(function() {
    // Get the current scroll percentage.
    const scrollPercentage = $(window).scrollTop() / $(window).height() * 100;

    // Check if the scroll percentage is 90%.
    if(!$('header').data('change-bg')) return;

    if (scrollPercentage >=3) {
      // Add the `background-active` class to the header.
      $('header').addClass('background-active');
    } else {
      // Remove the `background-active` class from the header.
      $('header').removeClass('background-active');
    }
  });
/**
 * detect if element in viewport javascript on scroll
 * using intersection observer
 * @param {HTMLElement} el
 * @param {number} offset
 * @returns {boolean}
 */

function isElementInViewport(el, offset = 0) {
    const { top, bottom } = el.getBoundingClientRect();
    const vHeight = (window.innerHeight || document.documentElement.clientHeight);

    return (
        (top > 0 || bottom > 0) &&
        top < vHeight + offset
    );
}

const options = {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No additional margin
    //if view width is less than 675p, trigger when 35% of the element is visible else 50%
    threshold: window.innerWidth < 675 ? 0.35 : 0.5,
  };

/**
 * if element with class .img-bottom-text-card enters viewport on scroll
 * add class .active
 */
$(document).ready(function(){
    const imgBottomTextCardContainer = $('.image-bottom-paragraph').parent();
    const imgBottomTextCardsObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                let cards = $(entry.target).find('> div');
                startCardAnimation(cards);
            }
        });
    }, options);
    each(imgBottomTextCardContainer, (index, row) => {
        imgBottomTextCardsObserver.observe(row);
    });
});

function startCardAnimation(cards) {
    forEach(cards, (card, index) => {
        if($(card).hasClass('active')) return;

        $(card).addClass('active');
        card = new SlideInCard(card);
        card.startAnimation(index * 300);
    });
}
const shadedRows = document.querySelectorAll('.shaded-rows');
const shadedRowsObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            $(entry.target).addClass('active');
        }
    });
}, {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No additional margin
    threshold: .6,
});
each(shadedRows, (index, row) => {
    shadedRowsObserver.observe(row);
});


$(window).on('load', function(){
    $('.loadingspinner').fadeOut();
});


$(document).scroll(function() {
    const scrollPercentage = Math.floor(($('header').offset().top) / $(window).height() * 100);
    if (scrollPercentage >= 20 && !$('.scroll-up').hasClass('active')) {
        $('.scroll-up').addClass('active');
    } else if(scrollPercentage < 20 && $('.scroll-up').hasClass('active')){
        $('.scroll-up').removeClass('active');
    }
  });

const footer = document.querySelector('footer');
const footerObserver = new IntersectionObserver(
        (entries) => {

        entries.forEach((entry) => {
            if (entry.isIntersecting && $('.scroll-up').hasClass('active')) {
                $('.scroll-up').css('bottom', `${footer.offsetHeight + 16}px`)
            } else {
                $('.scroll-up').css('bottom', '')
            }
        });
    },
    {
        root: null, // Use the viewport as the root
        rootMargin: '0px', // No additional margin
        //if view width is less than 675p, trigger when 35% of the element is visible else 50%
        threshold: window.innerWidth < 675 ? 0.35 : 0.15,
    }
);
if(footer)
    footerObserver.observe(footer);
