const BezierEasing = require('bezier-easing');
export default class SlideInCard {
    card;
    duration = 1000;
    slideInStarttime = null;
    rotateStarttime = null;
    initialXvw = 100;
    // create ease-out using BezierEasing
    easing = BezierEasing(0.25, 0.1, 0.25, 1.0);

    constructor(el) {
        this.card = el;
    }

    slideIn(timestamp) {
        if(!this.slideInStarttime) this.slideInStarttime = timestamp;
        const elapsed = timestamp - this.slideInStarttime; //runtime
        const progress = Math.min(elapsed / this.duration, 1);

        const easedProgress = this.easing(progress);

        const left = this.initialXvw * (1 -Math.min(easedProgress, 1));
        this.card.style.translate = `${left}vw 0 0`;

        // if anğimation is 90% complete, trigger rotation
        if (easedProgress > 0.9) {
            window.requestAnimationFrame(this.rotate.bind(this));
        }

        if (elapsed < this.duration) {
            window.requestAnimationFrame(this.slideIn.bind(this));
        }

    }
    rotate(timestamp) {
        if(!this.rotateStarttime) this.rotateStarttime = timestamp;
        const elapsed = timestamp - this.rotateStarttime; //runtime
        const progress = Math.min(elapsed / this.duration, 1);

        const easedProgress = this.easing(progress);

        const rotation = -180 * Math.min(easedProgress, 1);
        this.card.style.rotate = `y ${rotation}deg`;

        if (elapsed < this.duration) {
            window.requestAnimationFrame(this.rotate.bind(this));
        }
    }

    startAnimation( delay = 0) {
        // console.log(this);
        setTimeout((self) => {
            window.requestAnimationFrame(this.slideIn.bind(self));
        }, delay, this);
    }

}
