// "use strict";

/*
 |--------------------------------------------------------------------------
 | Global Helper Definitions
 |--------------------------------------------------------------------------
 |
 | Global js functions are defined here.
 | Functions will be defined with '__' prefix as a unusual enterprise's standard
 |
 */


// global.__isString = (obj) => {
//     return (Object.prototype.toString.call(obj) === '[object String]');
// }

// global.__isObject = (obj) => {
//     return Object.prototype.toString.call(obj) === '[object Object]';
// };

/**
 * This fil will contain all processes that is needed to
 * operate on forms.
 * Validation functions, event listeners and other necessary components
 * will be contained here.
 * All functions will be access from global object formProcesses.
 * since functions will be accessed through the object, __fnName notation will not be used.
 */




(function(){
    $(document).ready(function (param) {

        $('form.validation input:not([type=image],[type=button],[type=submit],[type=checkbox],[type=select],[type=radio])[required], textarea[required]').on('input', function(e){
            formOperations.clearErrorMessage( $(e.target).attr('name') );
            if(formOperations.validateForm( $(e.target).closest('form'), false)){
                formOperations.enableFormSubmit($(e.target).closest('form'))
            }else{
                formOperations.disableFormSubmit($(e.target).closest('form'));
            }
        });
        $('form.validation input:not([type=image],[type=button],[type=submit],[type=checkbox],[type=select],[type=radio])[required], textarea[required]').on('blur', function(e){
            if(formOperations.validateForm( $(e.target).closest('form'), false)){
                formOperations.enableFormSubmit($(e.target).closest('form'))
            }else{
                formOperations.disableFormSubmit($(e.target).closest('form'));
            }
        });

        $('form.validation input:not([type=image],[type=button],[type=submit],[type=checkbox],[type=select],[type=radio])[required], textarea[required]').on('focusout', function(e){

            if( formOperations.validateForm( $(e.target).closest('form'), false) ){
                formOperations.enableFormSubmit($(e.target).closest('form'))
            }else{
                formOperations.validateInput( e.target );
                formOperations.disableFormSubmit($(e.target).closest('form'))
            }
        });
        $('form.validation input[type=checkbox][required], form.validation input[type=select][required], textarea[required]').on('change', function(e){
            formOperations.validateInput( e.target );

            if( formOperations.validateForm( $(e.target).closest('form'), false) ){
                formOperations.enableFormSubmit($(e.target).closest('form'))
            }else{
                formOperations.disableFormSubmit($(e.target).closest('form'))
            }
        });
    })
    window.__log = console.log;
    var formOperations = {
        isEmail: (email) =>  {
            var regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            return regex.test(email);
        },
        showErrorMessage: (name, message = "") => {
            if(message != ''){
                $(`.invalid-feedback[for="${name}"]`).html(message);
            }
            $(`.invalid-feedback[for="${name}"]`).show();
        },
        clearErrorMessages: (form) => {
            form.find('.invalid-feedback').each((i, item) => {
                $(item).hide();

            })
        },
        clearErrorMessage: (name) =>{
            $(`.invalid-feedback[for="${name}"]`).hide();
        },

        validateForm: (form, showMessage=true) => {
            let isValidForm = true;

            $(form).find('input:not([type="submit"])[required], textarea[required]').each((i, el) => {
                if(el.hasAttribute('required')
                ) {
                    let _valid = formOperations.validateInput(el, showMessage);
                    if(!_valid && isValidForm)
                        isValidForm = false;
                }
            })

            return isValidForm;
        },

        validateInput: (el, showMessage=true) => {
            let input = $(el);
            let val = input.val();
            let name = input.attr('name');

            let valid = true;
            switch ( input.attr('type') ) {
                case 'text':
                    if(val == ''){
                        valid = false;
                        if(showMessage)
                            formOperations.showErrorMessage(name,'Lütfen bu alanı doldurun.')
                    }
                    break;
                case 'email':
                    if(!formOperations.isEmail(val)){
                        valid = false;
                        if(showMessage)
                            formOperations.showErrorMessage(name, 'Lütfen geçerli bir email adresi girin.')
                    }
                    break;
                case 'tel':
                    if(!__isValidPhone(el)){
                        valid = false;
                        if(showMessage)
                            formOperations.showErrorMessage(name, __phoneError(el), 'Lütfen geçerli bir telefon girin.')
                    }
                    break;
                case 'checkbox':
                    if (!$(input).is(':checked')){
                        valid = false;
                        if(showMessage)
                            formOperations.showErrorMessage($(input).attr('name'), 'Lütfen bu seçeneği işaretleyin.' );
                    }
                    break;
                case 'radio':
                    let name = input.attr('name');
                    //get all inputs with same name from the parent form
                    let inputs = $(input).closest('form').find(`input[name="${name}"]`);
                    // check if any of the inputs is checked
                    let checked = false;
                    inputs.each((i, item) => {
                        if($(item).is(':checked')){
                            checked = true;
                            return true;
                        }
                    });

                    //if there is no checked input then the form is not valid
                    if(!checked){
                        valid = false;
                        // console.log("Lütfen bu seçeneği işaretleyin");
                        if(showMessage)
                            formOperations.showErrorMessage(name, 'Lütfen bu seçeneği işaretleyin.' );
                        break;
                    }
                    // if the parent form has at least one radio checked but it is not the current one, continue
                    if(checked && !$(input).is(':checked')){
                        break;
                    }
                    // if the parent form has at least one radio with the samme name checked and the checked input does not have an empty value then the input is valid, otherwise it is not
                    if(checked && $(input).is(':checked') && $(input).val() == ''){
                        // console.log(name, "Lütfen bu seçeneği işaretleyin");
                        valid = false;
                        if(showMessage)
                            formOperations.showErrorMessage(name, 'Lütfen bu seçeneği işaretleyin.' );
                    }
                    break;
                default:
                    break;
            }

            return valid;
        },

        disableFormSubmit: (form) => {
            $(form).find('input[type=submit]').prop('disabled', true)
        },

        enableFormSubmit: (form) => {
            $(form).find('input[type=submit]').removeAttr('disabled')
        }
    }
    global.formOperations = formOperations;

    $('.newsletter input[name="TYPE"]').on('change', function(e){
        const anchor = $(this).parents('.newsletter').find(`label.kvkk span a`);
        switch (this.value) {
            case 'isveren':
                anchor.attr('href', $employer_kvkk);
                break;
            case 'isarayan':
                anchor.attr('href', $employee_kvkk);
                break;
        }
    });
})();


